import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private nombreAplicacion: string;
  private urlService: string;
  private urlIntranet: string;
  private urlApp: string;
  private pushPublicKey: string;

  constructor() {
    this.nombreAplicacion = 'Esmasa';

    //this.urlIntranet = 'http://localhost:17675/'; // Pruebas
    //this.urlIntranet = 'http://localhost:61373/'; // Pruebas
    //this.urlIntranet = 'https://es.fontventa.com/reqbin/vgepZPAngBhFeD0OWoPM9anwbng/'; // Pruebas reqbin
    //this.urlIntranet = 'http://intranet.devotrack.es/'; // Producción
    //this.urlIntranet = 'https://esmasa.fontventa.com/'; // Producción
    this.urlIntranet = 'https://empresasback.esmasalcorcon.com/'
    // this.urlIntranet = 'https://backesmasa.fontventa.com/'


    this.urlService = this.urlIntranet + 'api';
    this.urlApp = 'https://esmasalcorcon.com/';

    this.pushPublicKey = "BJMKU8hhRDDlBfWrIPC7QUpwJ3o9gq1qZpIDdPRUCk14PrYD2k7rG8FW0feuwa1QQECsQoHn1DKDh3AcNrnd2RA";
  }

  public getPushPublicKey(): string {
    return this.pushPublicKey;
  }

  public getUrlService(noApi?: boolean): string {
    if (noApi !== undefined && noApi === true) {
      return this.urlIntranet;
    } else {
      return this.urlService;
    }
  }

  public getUrlApp(): string {
    return this.urlApp;
  }

  public getNombreAplicacion() {
    return this.nombreAplicacion;
  }

}
